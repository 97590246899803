(function(self) {

  // find widget divs and keep track of them in wgtDivs by type
  self.wgtDivs = {};
  var allElems = document.getElementsByTagName('*');
  for (var i = 0, n = allElems.length; i < n; i++) {
    var wgtType;
    if (wgtType = allElems[i].getAttribute('data-huni-widget')) {
      self.wgtDivs[wgtType] = self.wgtDivs[wgtType] || [];
      self.wgtDivs[wgtType].push(allElems[i]);
    }
  }

  // fetch scripts for each type found
  var wgtTypes = [];
  for (var wgtType in self.wgtDivs) {
    if (self.wgtDivs.hasOwnProperty(wgtType)) {
      wgtTypes.push(wgtType);
      var scriptTag = document.createElement('script');
      scriptTag.src = self.lab + '/huni-widget-' + wgtType + '.js';
      scriptTag.type = "text/javascript";
      scriptTag.async = true;
      document.getElementsByTagName('head')[0].appendChild(scriptTag);
    }
  }

  // load css if we found any widgets
  if (wgtTypes.length) {
    var styleHrefs = ['cleanslate.css', 'huni-widget.css'];
    for (var i = 0, n = styleHrefs.length; i < n; i++) {
      var styleTag = document.createElement("link");
      styleTag.rel = "stylesheet";
      styleTag.type = "text/css";
      styleTag.href = self.lab + '/css/' + styleHrefs[i];
      styleTag.media = "all";
      styleTag.async = true;
      document.getElementsByTagName('head')[0].appendChild(styleTag);
    }
  }

  // helpfer function to relace widget placeholder divs with
  //  widget supplied html
  self.insertWgts = function(wgtType, wgtHtml) {
    var wgtDivs = self.wgtDivs[wgtType];
    for (var i = 0, n = wgtDivs.length; i < n; i++) {
      var wgtSpan;
      wgtSpan = document.createElement("span");
      wgtSpan.className = 'cleanslate huni-widget container';
      wgtSpan.innerHTML = wgtHtml;
      wgtDivs[i].parentNode.insertBefore(wgtSpan, wgtDivs[i].nextSibling);
      wgtDivs[i].parentNode.removeChild(wgtDivs[i]);
    }
  }

  // helper function to query Solr service and pass data back via callback
  self.solrRequest = function(query,callback) {
    self.solrResponse = callback;
    var jsonpReq = document.createElement('script');
    jsonpReq.src = self.solr + '/select?' + query + '&wt=json&json.wrf=' + self.wgtname + '.solrResponse';
    jsonpReq.type = "text/javascript";
    jsonpReq.async = true;
    document.body.appendChild(jsonpReq);
    document.body.removeChild(jsonpReq);
  };

  return self;

})(huni_097b8a38_9eb1_4bdb_9c53_bfffa9a924c4);
